import { computed, ref } from '@vue/composition-api';

export function useDataIterator(args: { items: any[], sortBy: string, keys: string[] }) {
    const items = ref<any[]>(args.items);
    const search = ref<string>('');
    const filter = ref<object>({});
    const sortDesc = ref<boolean>(false);
    const page = ref<number>(1);
    const itemsPerPage = ref<number>(50);
    const sortBy = ref(args.sortBy);
    const itemsPerPageArray = ref([5, 10, 50, 100]);
    const keys = ref(args.keys);

    const numberOfPages = computed<number>(() => Math.ceil(items.value.length / itemsPerPage.value));

    function nextPage() {
        if (page.value + 1 <= numberOfPages.value) {
            page.value += 1;
        }
    }

    function previousPage() {
        if (page.value - 1 >= 1) {
            page.value -= 1;
        }
    }

    function updateItemsPerPage(number) {
        itemsPerPage.value = number;
    }

    return {
        search,
        sortDesc,
        filter,
        itemsPerPage,
        page,
        sortBy,
        itemsPerPageArray,
        numberOfPages,
        keys,

        nextPage,
        previousPage,
        updateItemsPerPage,
    }
}
