















































import SelectorCard from "@/shared/components/layout/SelectorCard.vue"
import ButtonConfirm from "@/shared/components/layout/ButtonConfirm.vue";

import { defineComponent, ref, watch } from '@vue/composition-api'
import { CategoryProperties } from '@/modules/categories/models/category';


export default defineComponent({
  components: {
    SelectorCard,
    ButtonConfirm,
  },
  props: {
    defaultValue: {
      type: Object,
      required: true,
    },
    open: {
      type: Boolean,
      defaultValue: false,
    },
    loading: {
      type: Boolean,
      defaultValue: false,
    },
  },
  setup(props, context) {
    const nameInput = ref();
    const category = ref<CategoryProperties>({...props.defaultValue} as CategoryProperties);

    function cancel() {
      context.emit('cancel')
    }

    function remove() {
      context.emit('remove', category.value)
    }

    function save() {
      context.emit('save', category.value)
    }

    function focusInput() {
      nameInput.value.focus();
    }

    watch(() => props.open, () => {
      category.value = {...props.defaultValue} as CategoryProperties

      if(props.open) {
        setTimeout(() => {
          focusInput()
        }, 0)
      }
    })

    return {
      category,
      remove,
      save,
      cancel,
      nameInput,
    }
  },
})
