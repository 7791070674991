


































import SelectorCard from "@/shared/components/layout/SelectorCard.vue"
import ButtonConfirm from "@/shared/components/layout/ButtonConfirm.vue";

import { defineComponent, onMounted, ref, watch } from '@vue/composition-api'
import { CategoryProperties } from '@/modules/categories/models/category';


export default defineComponent({
  components: {
    SelectorCard,
    ButtonConfirm,
  },
  props: {
    category: {
      type: Object,
      required: true,
    },
    showSave: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const nameInput = ref();
    const updatedCategory = ref<CategoryProperties>({...props.category} as CategoryProperties);

    function remove() {
      context.emit('remove', updatedCategory.value)
    }

    function save() {
      context.emit('save', updatedCategory.value)
    }

    watch(() => props.category, () => {
      nameInput.value?.focus()
      updatedCategory.value = {...props.category} as CategoryProperties
    })

    onMounted(() => {
      nameInput.value?.focus()
    });


    return {
      updatedCategory,
      remove,
      save,
      nameInput,
    }
  },
})
