










































































































































import SelectorCard from "@/shared/components/layout/SelectorCard.vue"
import ButtonConfirm from "@/shared/components/layout/ButtonConfirm.vue";

import {computed, defineComponent, ref} from '@vue/composition-api'
import {CategoriesService} from '@/shared/store/actions/categories';
import ButtonAdd from '@/shared/components/layout/ButtonAdd.vue';
import {CategoryProperties} from '@/modules/categories/models/category';
import CategoryCard from '@/modules/categories/components/CategoryCard.vue';
import ToolbarFloating from '@/shared/components/layout/ToolbarFloating.vue';
import CreateCategoryDialog from '@/modules/categories/components/CreateCategoryDialog.vue';
import {Portal} from 'portal-vue';
import {useDataIterator} from '@/shared/helpers/useDataIterator';
import ButtonPrimary from "@/modules/categories/ButtonPrimary.vue";
import Search
  from "@/modules/categories/Search.vue";


export default defineComponent({
  components: {
    Search,
    ButtonPrimary,
    SelectorCard,
    ButtonConfirm,
    CategoryCard,
    ButtonAdd,
    ToolbarFloating,
    CreateCategoryDialog,
    Portal,
  },
  setup(props, {root}) {
    const createDialogOpen = ref(false);
    const createCategoryLoading = ref(false);
    const newCategory = ref<Partial<CategoryProperties>>({});
    const categories = computed<CategoryProperties[]>(() => root.$store.getters.getCategories)

    const activeCategory = computed<CategoryProperties>(() => root.$store.getters.getSelectedCategory)
    const categoryService = new CategoriesService(root.$store)

    const dataIterator = useDataIterator({
      items: categories.value,
      sortBy: 'name',
      keys: ['Name', 'Description'],
    });

    function selectCategory(id: string) {
      categoryService.selectCategory(id)
    }

    function removeCategory(category: CategoryProperties) {
      categoryService.deleteCategory(category.id)
    }

    function createCategory() {
      newCategory.value = {
        name: '',
        description: '',
      }

      createDialogOpen.value = true;
    }

    function cancelCreateCategory() {
      createDialogOpen.value = false;
    }

    async function saveCategory(category: CategoryProperties) {
      if (createDialogOpen.value) {
        createCategoryLoading.value = true
        const result = await categoryService.createCategory(category)
        createCategoryLoading.value = false
        createDialogOpen.value = false

        selectCategory(result.id)
      } else {
        await categoryService.updateCategory(category);
      }
    }

    categoryService.fetchCategories()
    categoryService.selectCategory('')

    return {
      categories,
      activeCategory,
      createDialogOpen,
      newCategory,
      createCategoryLoading,

      selectCategory,
      removeCategory,
      saveCategory,
      createCategory,
      cancelCreateCategory,

      ...dataIterator,
    }
  },
})
